<template>
  <navigation-bar/>
  <!-- Header Section -->
<!--  <div class="header-container">-->
<!--    <div class="container-fluid header-content">-->
<!--      <div class="row">-->
<!--        <div class="col-12 py-5 header-image d-flex align-items-center justify-content-center text-center">-->
<!--          <div class="DesktopDisplayHugeBold grayscaleOffWhite px-5">-->
<!--            Join us on our journey to save the environment and its biodiversity-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <section class="container-fluid my-5 p-5 d-flex justify-content-center">-->
<!--    <div class="row mw-1800px">-->
<!--      <div class="col-lg-5 order-lg-last">-->
<!--        <picture>-->
<!--          &lt;!&ndash; <source media="(min-width: 400px)" srcset="/theark_search.png"> &ndash;&gt;-->
<!--          <img src="https://cdn.theark.co/css/theark_team.jpeg" class="img-fluid" alt="THE ARK team">-->
<!--        </picture>-->
<!--      </div>-->
<!--      <div class="col-lg-7 mt-5 mt-lg-0">-->
<!--        <div class="DesktopDisplayMedium">-->
<!--          A team on a clear mission-->
<!--        </div>-->
<!--        <div class="py-3">-->
<!--          THE ARK is headquartered in Zurich, Switzerland and consists of an interdisciplinary team combining knowledge from conservation, finance and technology. We are aiming to collaborate with key stakeholders that want to create positive change on our planet! Our mission is to empower every single entity and person to contribute to nature and biodiversity.-->
<!--        </div>-->
<!--        <div class="d-flex align-items-center flex-column">-->
<!--          <div class="DesktopDisplaySmall pt-4 mw-700px text-center">-->
<!--            “We want to ensure the diversity of life on our planet for the future generations”-->
<!--          </div>-->
<!--          <div class="DesktopDisplaySmall pt-4 mw-700px text-center">-->
<!--            THE ARK team-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </section>-->
  <section class="video-container">
    <video
      class="video-js vjs-default-skin vjs-big-play-centered"
      controls
      preload="auto"
      width="100%"
      height="auto"
      data-setup='{}'
      >
      <source src="https://cdn.theark.co/video.mp4" type="video/mp4" />
      <source src="https://cdn.theark.co/video.webm" type="video/webm" />
      <p class="vjs-no-js">
        To view this video please enable JavaScript, and consider upgrading to a
        web browser that
        <a rel="noopener noreferrer" href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
      </p>
    </video>
  </section>
  <section class="container-fluid p-5 secondaryLightBckg d-flex justify-content-center">
    <div class="row mw-1800px py-3">
      <div class="col-12 d-flex justify-content-center DesktopDisplaySmall mb-4 pb-4">
        THE ARK Team
      </div>
      <div class="col-md-6 col-lg-3 d-flex align-items-center flex-column mb-5 mb-lg-0">
        <img src="/ark_team/cedric_kohler.jpg" width="240" />
        <a href="https://www.linkedin.com/in/cedrickoehler/" target="_blank">
          <div class="text-center py-4 DesktopDisplayXSmallBold">Cedric Köhler</div>
        </a>
        <div class="text-center DesktopTextMedium">Co-Founder</div>
      </div>
      <div class="col-md-6 col-lg-3 d-flex align-items-center flex-column mb-5 mb-lg-0">
        <img src="/ark_team/qasim_abbas.jpg" width="240" />
        <a href="https://www.linkedin.com/in/qasim-abbas-530b49a7/" target="_blank">
          <div class="text-center py-4 DesktopDisplayXSmallBold">Qasim Abbas</div>
        </a>
        <div class="text-center DesktopTextMedium">Co-Founder</div>
      </div>
      <div class="col-md-6 col-lg-3 d-flex align-items-center flex-column mb-5 mb-lg-0">
        <img src="/ark_team/ahmed_warreth.jpg" width="240" />
        <a href="https://ch.linkedin.com/in/ahmedwarreth" target="_blank">
          <div class="text-center py-4 DesktopDisplayXSmallBold">Ahmed Warreth</div>
        </a>
        <div class="text-center DesktopTextMedium">Chief Product Officer </div>
      </div>
      <div class="col-md-6 col-lg-3 d-flex align-items-center flex-column mb-5 mb-lg-0">
        <img src="/ark_team/larissa_lewis.jpg" width="240" />
        <a href="https://ch.linkedin.com/in/larissa-s-lewis" target="_blank">
          <div class="text-center py-4 DesktopDisplayXSmallBold">Larissa Lewis</div>
        </a>
        <div class="text-center DesktopTextMedium">Head of People</div>
      </div>
    </div>
  </section>
  <apply-prompt />
  <demo-form ref="demoFormRef"/>
  <page-footer />
</template>

<script>
import NavigationBar from './NavigationBar.vue';
import ApplyPrompt from './ApplyPrompt.vue';
import DemoForm from '../../demo/components/DemoForm.vue'
import PageFooter from './PageFooter.vue';

export default {
  components: {
    NavigationBar,
    ApplyPrompt,
    DemoForm,
    PageFooter
  },
  data() {
    return {

    };
  },
  methods: {

  },
};
</script>

<style scoped>
body {
  background-color: white !important;
}

.header-image {
  //background-image: url(https://cdn.theark.co/css/theark_about_header.png);
  //background-position: bottom left;
  //background-repeat: no-repeat;
  //background-size: cover;
  //min-height: 750px;
}


@media (max-width: 500px) {
  .header-image {
    min-height: 0px;
  }
}

.header-container {
  background-color: black;
}

a {
  color: #478964 !important;
}

a:hover {
  color: #6AAC8B !important;
}

.video-container {
  position: relative;
  height: 100vh;
}

video {
  object-fit: contain;
}

.video-js {
  position: absolute;
  height:100%;
  width:100%;
  overflow: hidden;
}

.video-js video {
  min-width: 100%;
  min-height: 100%;
}

.secondaryLightBckg img {
  border-radius: 50%;
  object-fit: cover;
  width: 240px;
  height: 240px;
}
</style>
