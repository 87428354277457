<template>
  <navigation-bar/>
  <!-- Header Section -->
  <div class="header-container">
    <div class="container-fluid header-content">
      <div class="row">
        <div class="col-12 py-5 header-image d-flex align-items-center justify-content-center text-center flex-column">
          <div class="DesktopDisplayXSmallBold grayscaleOffWhite pb-4">CLIENT SOLUTIONS</div>
          <div class="DesktopDisplayHugeBold grayscaleOffWhite px-5">
            Manage investment operations and measure impact
          </div>
          <div class="header-buttons py-4">
            <button class="btn btn-lg btn-primary2-ark" @click="scrollToDemoForm">Book Demo</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="container-fluid p-5 d-flex justify-content-center secondaryLightBckg">
    <div class="row mw-1800px">
      <div class="col-lg-6 pt-lg-5">
        <div class="DesktopDisplaySmall">
          Portfolio management
        </div>
        <div class="py-lg-3 mb-5">
          Upload your investment portfolio and have clarity on the impact created. THE ARK platform automatically creates a personalised dashboard and allows users to quickly create impact reports.
        </div>
        <div class="DesktopDisplaySmall">
          Engage stakeholders with automated reporting
        </div>
        <div class="py-3 mb-5">
          Features such as portfolio management and impact dashboards can be shared with relevant stakeholders within seconds. Furthermore THE ARK creates automated reporting by sourcing open source data sets such as habitat, species and CO<sub>2</sub>.
        </div>
        <div class="DesktopDisplaySmall">
          Invite co-investors to participate
        </div>
        <div class="py-3 mb-5">
          Users can share a project with potential co-investors for funding. This also allows alignment on collective reporting metrics between investors and reduces the overall admin efforts.
        </div>
      </div>
      <div class="col-lg-6 d-flex justify-content-center">
        <img src="https://cdn.theark.co/css/theark_impact.png" />
      </div>
    </div>
  </section>
  <demo-form ref="demoFormRef"/>
  <page-footer />
</template>

<script>
import NavigationBar from './NavigationBar.vue';
import ImageAnimation from './ImageAnimation.vue';
import DemoForm from '../../demo/components/DemoForm.vue'
import PageFooter from './PageFooter.vue';

export default {
  components: {
    NavigationBar,
    ImageAnimation,
    DemoForm,
    PageFooter
  },
  data() {
    return {

    };
  },
  methods: {
    // Method to scroll to the demo-form component
    scrollToDemoForm() {
      // Get the demo-form component using the ref
      const demoFormElement = this.$refs.demoFormRef;
      // Check if the element exists before scrolling
      if (demoFormElement) {
        demoFormElement.$el.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style scoped>
body {
  background-color: white !important;
}

.header-image {
  //background-image: url(https://cdn.theark.co/css/theark_measure_header.png);
  //background-position: bottom left;
  //background-repeat: no-repeat;
  //background-size: cover;
  //min-height: 750px;
}

img {
  width: 70%;
  height: auto;
  object-fit: contain;
}

@media (max-width: 499px) {
  img {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .header-image {
    min-height: 0px;
  }
}

.header-container {
  background-color: black;
}

a {
  color: #478964 !important;
}

a:hover {
  color: #6AAC8B !important;
}

@media (min-width: 1501px) {
  .image-animation-row {
    min-height: 694px;
  }
}

</style>
