<template>
  <navigation-bar/>
  <!-- Header Section -->
  <div class="header-container">
    <div class="container-fluid header-content">
      <div class="row pt-5">
        <div class="col-lg-6 py-5 pl-5">
          <div class="header-title DesktopDisplayHugeBold grayscaleOffWhite py-2">
            Accelerate your impact
          </div>
<!--          <div class="header-subtitle MobileTextLarge grayscaleOffWhite mt-3 py-2">-->
<!--            Invest in nature positive assets. Build trust and transparency among stakeholders.-->
<!--          </div>-->
          <div class="header-buttons py-4">
            <button class="btn btn-secondary7-ark" @click="scrollToDemoForm">Book Demo</button>
<!--            <a href="/apply" class="btn btn-outline btn-secondary7-ark-outline textTransformNone ml-3">Apply</a>-->
          </div>
        </div>
        <div class="col-lg-6 header-image"></div>
      </div>
    </div>
  </div>
  <logo-carousel />
<!--  <section class="container-fluid my-5 p-5 d-flex justify-content-center">-->
<!--    <div class="row mw-1800px">-->
<!--      <div class="col-lg-6">-->
<!--        <video-section :sectionVideoURL="sectionAVideoURL"/>-->
<!--      </div>-->
<!--      <div class="col-lg-6 mt-5 mt-lg-0">-->
<!--        <section-list :headerText="headerAText" :sectionData="sectionAData" />-->
<!--      </div>-->
<!--    </div>-->
<!--  </section>-->
<!--  <section class="container-fluid my-5 p-5 secondaryLightBckg d-flex justify-content-center">-->
<!--    <div class="row mw-1800px image-animation-row">-->
<!--      <div class="col-lg-6 order-lg-last image-animation-col">-->
<!--        <image-animation-->
<!--          first-image-src="https://cdn.theark.co/css/theark_discovery.png"-->
<!--          second-image-src="https://cdn.theark.co/css/theark_discovery_eia.png"-->
<!--        />-->
<!--      </div>-->
<!--      <div class="col-lg-6 mt-5 mt-lg-0">-->
<!--        <section-list :headerText="headerBText" :sectionData="sectionBData" />-->
<!--      </div>-->
<!--    </div>-->
<!--  </section>-->
<!--  <section class="container-fluid my-5 p-5 d-flex justify-content-center">-->
<!--    <div class="row mw-1800px">-->
<!--      <div class="col-lg-6">-->
<!--        <video-section :sectionVideoURL="sectionCVideoURL"/>-->
<!--      </div>-->
<!--      <div class="col-lg-6 mt-5 mt-lg-0">-->
<!--        <section-list :headerText="headerCText" :sectionData="sectionCData" />-->
<!--      </div>-->
<!--    </div>-->
<!--  </section>-->
<!--  <section class="container-fluid mt-5 p-5 primaryDarkBckg grayscaleOffWhite d-flex justify-content-center">-->
<!--    <div class="row mw-1800px justify-content-center">-->
<!--      <div class="col-lg-12 DesktopDisplayMediumBold text-center py-5">How will you use THE ARK platform?</div>-->
<!--      <div class="col-lg-11 DesktopTextMedium text-center pb-5">-->
<!--        We are working with leading conservation organisations and capital markets such as corporates, financial institutions, foundations and private donors.-->
<!--      </div>-->
<!--    </div>-->
<!--  </section>-->
<!--  <section class="container-fluid p-5 primaryDarkBckg d-flex justify-content-center position-relative">-->
<!--    <picture class="abs-dec-first">-->
<!--      &lt;!&ndash; <source media="(min-width: 400px)" srcset="/theark_search.png"> &ndash;&gt;-->
<!--      <img src="https://cdn.theark.co/css/report_green.png" class="img-fluid" alt="THE ARK Portfolio features">-->
<!--    </picture>-->
<!--    <picture class="abs-dec-second">-->
<!--      &lt;!&ndash; <source media="(min-width: 400px)" srcset="/theark_search.png"> &ndash;&gt;-->
<!--      <img src="https://cdn.theark.co/css/invest_green.png" class="img-fluid" alt="THE ARK Portfolio features">-->
<!--    </picture>-->
<!--    <div class="row mw-1400px p-5 rel">-->
<!--      <div class="col-lg-6 grayscaleOffWhite">-->
<!--        <user-journey-card-->
<!--          card-title="Manage investment operations and measure impact"-->
<!--          card-content="THE ARK enables you to centralise information, measure and report your impact to relevant stakeholders. Share automatically created portfolio impact reports within seconds."-->
<!--          button-text="Learn more"-->
<!--          button-link="/impact"-->
<!--        />-->
<!--      </div>-->
<!--      <div class="col-lg-6 grayscaleOffWhite mt-5 mt-lg-0">-->
<!--        <user-journey-card-->
<!--          card-title="Create an individual investment portfolio"-->
<!--          card-content="THE ARK offers you different investment and donation opportunities focusing on nature and biodiversity. Choose from a variety of assets and tailor your investment."-->
<!--          button-text="Learn more"-->
<!--          button-link="/investment"-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->
<!--  </section>-->
  <apply-prompt />
  <demo-form ref="demoFormRef"/>
  <page-footer />
</template>

<script>
import NavigationBar from './NavigationBar.vue';
import LogoCarousel from './LogoCarousel.vue';
import VideoSection from './VideoSection.vue';
import SectionList from './SectionList.vue';
import ImageAnimation from './ImageAnimation.vue';
import UserJourneyCard from './UserJourneyCard.vue';
import ApplyPrompt from './ApplyPrompt.vue';
import TestimonialCarousel from './TestimonialCarousel.vue'
import DemoForm from '../../demo/components/DemoForm.vue'
import PageFooter from './PageFooter.vue';

export default {
  components: {
    NavigationBar,
    LogoCarousel,
    VideoSection,
    SectionList,
    ImageAnimation,
    UserJourneyCard,
    TestimonialCarousel,
    ApplyPrompt,
    DemoForm,
    PageFooter
  },
  data() {
    return {
      // showMenu: false,
      // dropdownActive: null,
      headerImageURL: "https://cdn.theark.co/css/theark_home_header.png",
      sectionAVideoURL: "https://cdn.theark.co/css/theark_sdg_chart.mp4",
      headerAText: "Manage your impact investment operations",
      sectionAData: [
        "Fully integrated investment operations from deal flow to stakeholder reporting. Invite co-investors and collaborate on potential joint investments.",
        "Monitor your portfolio performance according to global standards like SDGs, ESG, IUCN Threats, TNFD and IRIS+.",
        "Benchmark and learn from other projects and leading organisations on the platform",
        "Build trust with auditable data sets to verify your portfolio impact."
        ],
      headerBText: "Create your individual portfolio",
      sectionBData: [
        "Choose from a variety of asset classes such as philanthropy, equity, biodiversity credits and bonds.",
        "Get in field insights directly from the project and organisation operators.",
        "Customise your impact reporting to your and your stakeholders needs.",
        "Adjust your portfolio at any time according to your impact criteria, supply chain strategy and risk."
        ],
      headerCText: "Drive decision making based on verified data",
      sectionCData: [
        "Amplify your nature intelligence with automated analytics from day one.",
        "Benchmark your impact with industry trends and other projects on the platform.",
        "Benefit from a knowledge base provided by top personnel with impact reports, research papers and project plans.",
        "Access THE ARK's proprietary biodiversity and climate data sets for project planning and investment decision making."
        ],
      sectionCVideoURL: "https://cdn.theark.co/css/theark_searching.mp4",
    };
  },
  methods: {
    // Method to scroll to the demo-form component
    scrollToDemoForm() {
      // Get the demo-form component using the ref
      const demoFormElement = this.$refs.demoFormRef;
      // Check if the element exists before scrolling
      if (demoFormElement) {
        demoFormElement.$el.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style scoped>
body {
  background-color: white !important;
}

.header-image {
  //background-image: url(https://cdn.theark.co/css/theark_home_header.png);
  //object-fit: cover;
  //background-position: bottom left;
  //background-size: cover;
  //min-height: 465px;
}

.header-container {
  background-color: black;
}

.header-buttons {
  /* Styles for the buttons in the header section */
  margin-top: 20px;
}

.btn-secondary7-ark-outline {
  color: white;
  border-color: white;
}

.btn-secondary7-ark-outline:hover {
  color: white;
  border-color: white;
}

a.btn-secondary7-ark-outline {
  color: white !important;
}

/* Hide the navbar with opacity when showNavBar is false */
.navbar-hide {
  opacity: 0;
}

@media (max-width: 499px) {
  .image-animation-col {
    min-height: 250px;
  }
}

@media (min-width: 500px) and (max-width: 993px) {
  .image-animation-col {
    min-height: 500px;
  }
}

@media (min-width: 1501px) {
  .image-animation-row {
    min-height: 694px;
  }
}

/* Decoration element styling */
.abs-dec-first,
.abs-dec-second {
  position: absolute;
  width: 200px;
}

.abs-dec-first {
  left: 5px;
  top: 5px;
}

.abs-dec-second {
  right: 5px;
  bottom: 5px;
}
</style>
