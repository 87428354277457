<template>
<!--  <section class="container-fluid bck-img py-5">-->
<!--    <div class="row">-->
<!--      <div class="col-12 d-flex justify-content-center text-center my-5">-->
<!--        <span class="DesktopDisplayLarge grayscaleOffWhite">-->
<!--          Get in contact with THE ARK-->
<!--        </span>-->
<!--      </div>-->
<!--      <div class="col-12 d-flex justify-content-center my-5">-->
<!--        <a href="/apply" class="btn btn-lg btn-primary2-ark">APPLY NOW</a>-->
<!--      </div>-->
<!--    </div>-->
<!--  </section>-->
</template>

<style scoped>
a.btn-primary2-ark {
  color: white !important;
}

.bck-img {
  background-image: url('https://cdn.theark.co/css/theark_forest.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
}
</style>
