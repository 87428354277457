<template>
  <footer class="container-fluid px-5 pt-5 pb-2">
    <div class="row mw-1800px mx-auto">
      <div class="col-md-6">
        <!-- Page Logo -->
        <div class="logo">
          <img src="https://cdn.theark.co/css/theark_logo_hnav_light.png" alt="Page Logo" />
        </div>
      </div>
      <div class="col-md-2 mt-3 mt-md-0">
        <div class="footer-column d-flex flex-column">
<!--          <h4 class="grayscaleOffWhite DesktopLinkMedium">THE ARK</h4>-->
<!--          <h4 class="grayscaleOffWhite DesktopLinkMedium">THE ARK</h4>-->
<!--          <a href="https://theark.co" class="DesktopTextMedium">Home</a>-->
<!--          <a href="/about" class="DesktopTextMedium">About</a>-->
        </div>
      </div>
      <div class="col-md-2 mt-3 mt-md-0">
        <div class="footer-column d-flex flex-column">
<!--          <h4 class="grayscaleOffWhite DesktopLinkMedium">Client Solutions</h4>-->
<!--          <a href="/impact" class="DesktopTextMedium">Portfolio Management</a>-->
<!--          <a href="/investment" class="DesktopTextMedium">Direct Investments</a>-->
          <h4 class="grayscaleOffWhite DesktopLinkMedium">THE ARK</h4>
          <a href="https://theark.co" class="DesktopTextMedium">Home</a>
          <a href="/about" class="DesktopTextMedium">About</a>
        </div>
      </div>
      <div class="col-md-2 mt-3 mt-md-0">
        <div class="footer-column d-flex flex-column">
          <!-- Privacy and Terms of Service Links -->
          <h4 class="grayscaleOffWhite DesktopLinkMedium">Company</h4>
          <a href="/privacy" target="_blank" class="DesktopTextMedium">Privacy Policy</a>
          <a href="/terms" target="_blank" class="DesktopTextMedium">Terms of Service</a>
        </div>
      </div>
      <!-- Social Links -->
      <div class="col-12 social-links my-4">
        <a href="https://www.facebook.com/THE-ARK-111672397636209" target="_blank" class="pr-3"><i class="fab fa-facebook"></i></a>
        <a href="https://www.linkedin.com/company/thearkcompany" target="_blank" class="pr-3"><i class="fab fa-linkedin"></i></a>
        <a href="https://www.instagram.com/thearknetwork/" target="_blank"><i class="fab fa-instagram"></i></a>
      </div>
      <!-- Trademark Note -->
      <div class="col-12">
        <p class="trademark-note d-flex align-items-center justify-content-center DesktopTextMedium grayscaleOffWhite">
          &copy;
          {{ currentYear }}
          Made for <i class="material-icons mx-2" aria-label="Public">public</i> by
          <a href="https://theark.co" target="_blank" class="ml-1">THE ARK</a>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
};
</script>

<style scoped>

.logo img {
  max-width: 200px;
  height: auto;
}

footer {
  background-color: #172822;
}

/* Adjust the marker size */
.social-links i {
  font-size: 25px;
}

footer a {
  color: #F0EDD8 !important;
}

</style>
