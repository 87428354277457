<template>
  <navigation-bar/>
  <!-- Header Section -->
  <div class="header-container">
    <div class="container-fluid header-content">
      <div class="row">
        <div class="col-12 py-5 header-image d-flex align-items-center justify-content-center text-center flex-column">
          <div class="DesktopDisplayXSmallBold grayscaleOffWhite pb-4">CLIENT SOLUTIONS</div>
          <div class="DesktopDisplayHugeBold grayscaleOffWhite px-5">
            Create an individual investment portfolio
          </div>
          <div class="header-buttons py-4">
            <button class="btn btn-lg btn-primary2-ark" @click="scrollToDemoForm">Book Demo</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="container-fluid p-5 d-flex justify-content-center secondaryLightBckg">
    <div class="row mw-1800px">
      <div class="col-lg-6 pt-lg-5">
        <div class="DesktopDisplaySmall">
          Matching your investment and donation goals
        </div>
        <div class="py-lg-3 mb-5">
          Based on a strict due diligence process with a peer review, we have pre-screened the best conservation projects globally and made them available via our platform. Our data stack finds the perfect investments based on your impact goal criteria (e.g. philanthropic, strategic, supply chain).
        </div>
        <div class="DesktopDisplaySmall">
          Learn about the impact you will create with your money
        </div>
        <div class="py-3 mb-5">
          THE ARK platform allows you to structure your investments and visualise the impact you have created within your portfolio. Use the real time dashboards to create auditable impact reports for your stakeholders.
        </div>
        <div class="DesktopDisplaySmall">
          Trust and transparency
        </div>
        <div class="py-3 mb-5">
          We provide clarity on in-field outcomes, use of funds and verification of the impact reported. THE ARK ensures that funding goes directly to the field by reducing overheads and streamlining fundraising processes for wildlife conservation organisations.
        </div>
      </div>
      <div class="col-lg-6 d-flex justify-content-center">
        <img src="https://cdn.theark.co/css/theark_investment.png" />
      </div>
    </div>
  </section>
  <demo-form ref="demoFormRef"/>
  <page-footer />
</template>

<script>
import NavigationBar from './NavigationBar.vue';
import ImageAnimation from './ImageAnimation.vue';
import DemoForm from '../../demo/components/DemoForm.vue'
import PageFooter from './PageFooter.vue';

export default {
  components: {
    NavigationBar,
    ImageAnimation,
    DemoForm,
    PageFooter
  },
  data() {
    return {

    };
  },
  methods: {
    // Method to scroll to the demo-form component
    scrollToDemoForm() {
      // Get the demo-form component using the ref
      const demoFormElement = this.$refs.demoFormRef;
      // Check if the element exists before scrolling
      if (demoFormElement) {
        demoFormElement.$el.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style scoped>
body {
  background-color: white !important;
}

.header-image {
  //background-image: url(https://cdn.theark.co/css/theark_investment_header.png);
  //background-position: bottom left;
  //background-repeat: no-repeat;
  //background-size: cover;
  //min-height: 750px;
}

img {
  width: 70%;
  height: auto;
  object-fit: contain;
}

@media (max-width: 499px) {
  img {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .header-image {
    min-height: 0px;
  }
}

.header-container {
  background-color: black;
}

a {
  color: #478964 !important;
}

a:hover {
  color: #6AAC8B !important;
}

@media (min-width: 1501px) {
  .image-animation-row {
    min-height: 694px;
  }
}

</style>
