<template>
  <header
    id="nav-menu"
    class="z-1000"
    aria-label="navigation bar"
    ref="navMenu"
  >
    <div class="containerz">
      <div class="nav-start">
        <a class="logo" href="/">
          <img src="https://cdn.theark.co/css/theark_logo_hnav_white.png" height="35" alt="THE ARK Logo" />
        </a>
        <div class="menu z-1000" :class="{ 'show': showMenu }">
          <ul class="menu-bar">
<!--            <li>-->
<!--              <button-->
<!--                class="nav-link dropdown-btn"-->
<!--                :data-dropdown="'dropdown1'"-->
<!--                :aria-expanded="dropdownActive === 'dropdown1'"-->
<!--                @click="toggleDropdown('dropdown1')"-->
<!--              >-->
<!--                Solutions-->
<!--                <i class="bx bx-chevron-down" aria-hidden="true"></i>-->
<!--              </button>-->
<!--              <div :id="'dropdown1'" class="dropdown white_bg" :class="{ 'active': dropdownActive === 'dropdown1' }">-->
<!--                <ul role="menu">-->
<!--                  <li role="menuitem">-->
<!--                    <a class="dropdown-link" href="/impact">-->
<!--                      <img src="https://cdn.theark.co/css/report.png" class="icon" width="50" height="50" />-->
<!--                      <div>-->
<!--                        <span class="dropdown-link-title">Portfolio Management</span>-->
<!--                        <p>Report impact</p>-->
<!--                      </div>-->
<!--                    </a>-->
<!--                  </li>-->
<!--                  <li role="menuitem">-->
<!--                    <a class="dropdown-link" href="/investment">-->
<!--                      <img src="https://cdn.theark.co/css/invest.png" class="icon" width="50" height="50" />-->
<!--                      <div>-->
<!--                        <span class="dropdown-link-title">Direct Investments</span>-->
<!--                        <p>Customise investment or donation</p>-->
<!--                      </div>-->
<!--                    </a>-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </div>-->
<!--            </li>-->
            <li><a class="nav-link" href="/about">About</a></li>
            <li class="display-600px d-none"><a href="/users/sign_in" class="nav-link">Log in</a></li>
<!--            <li class="display-600px d-none"><a href="/apply" class="nav-link">Apply</a></li>-->
          </ul>
        </div>
      </div>
      <div class="nav-end">

<!--        <div class="right-containerz">-->
<!--          <a href="/users/sign_in" class="nav-link">-->
<!--            Log in-->
<!--          </a>-->
<!--          <a href="/apply" class="btn btn-secondary7-ark">Apply</a>-->
<!--        </div>-->
        <!-- Hamburger menu button -->
        <button
          id="hamburger"
          aria-label="hamburger"
          :aria-expanded="showMenu"
          @click="toggleMenu"
        >
        <i class="fas fa-bars" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  data() {
    return {
      showMenu: false,
      dropdownActive: null,
    };
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
      if (this.showMenu) {
        // Close any active dropdown when the hamburger menu is opened
        this.closeDropdown();
      }
    },
    toggleDropdown(dropdownId) {
      if (this.dropdownActive === dropdownId) {
        // Close the dropdown if it's already active
        this.closeDropdown();
      } else {
        // Open the clicked dropdown
        this.dropdownActive = dropdownId;
        if (window.innerWidth >= 1100) {
          this.showMenu = false; // Close the hamburger menu if it's open
        }
      }
    },
    closeDropdown() {
      // Close the active dropdown
      this.dropdownActive = null;
    },
    handleOutsideClick(event) {
      // Click occurred outside the component, close the dropdown
      if (!this.$refs.navMenu.contains(event.target)) {
        this.closeDropdown();
      }
    },
  },
  mounted() {
    window.addEventListener("click", this.handleOutsideClick);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.handleOutsideClick);
  },
};
</script>


<style scoped>

a.btn-secondary7-ark {
  color: #172722 !important;
}

:root {
  --medium-grey: #636363;
  --light-grey: #eeeeee;
  --ash: #f4f4f4;
  --white: white;
  --border: 1px solid var(--light-grey);
  --shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: inherit;
}

.icon {
  padding: 0.5rem;
  background-color: var(--light-grey);
  border-radius: 10px;
}

.logo {
  margin-right: 1.5rem;
}

#nav-menu {
  border-bottom: var(--border);
  background-color: black;
}

.containerz {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 2rem;
  height: 90px;
  padding: 1.2rem 3rem;
}

.menu {
  position: relative;
}

.menu-bar {
  margin: 0px !important;
  background-color: black;
}

.menu-bar li:first-child .dropdown {
  flex-direction: initial;
  min-width: 480px;
}

.menu-bar li:first-child ul:nth-child(1) {
  border-right: var(--border);
}

.menu-bar li:nth-child(n + 2) ul:nth-child(1) {
  border-bottom: var(--border);
}

.menu-bar .dropdown-link-title {
  font-weight: 600;
}

#hamburger,
.right-containerz .nav-link,
.menu-bar .nav-link {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.6px;
  padding: 0.3rem;
  min-width: 60px;
  margin: 0 0.6rem;
  outline: none !important;
  color: #478964 !important;
}

#hamburger:focus,
.right-containerz .nav-link:hover,
.menu-bar .nav-link:hover,
.dropdown-link:hover {
  color: #6AAC8B !important;
}

.nav-start,
.nav-end,
.menu-bar,
.right-containerz,
.right-containerz .search {
  display: flex;
  align-items: center;
}

.dropdown {
  display: flex;
  flex-direction: column;
  min-width: 230px;
  background-color: var(--white);
  border-radius: 10px;
  position: absolute;
  top: 36px;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transform: scale(0.97) translateX(-5px);
  transition: 0.1s ease-in-out;
  box-shadow: var(--shadow);
}

.dropdown.active {
  visibility: visible;
  opacity: 1;
  transform: scale(1) translateX(5px);
}

.dropdown ul {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.2rem;
  font-size: 0.95rem;
}

.dropdown-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.15rem;
}

.dropdown-link {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem 0;
  border-radius: 7px;
  transition: 0.1s ease-in-out;
}

.dropdown-link p {
  font-size: 0.8rem;
  color: var(--medium-grey);
}

.right-containerz {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.right-containerz .search {
  position: relative;
}

.right-containerz img {
  border-radius: 50%;
}

.search input {
  background-color: var(--ash);
  border: none;
  border-radius: 6px;
  padding: 0.7rem;
  padding-left: 2.4rem;
  font-size: 16px;
  width: 100%;
  border: var(--border);
}

.search .bx-search {
  position: absolute;
  left: 10px;
  top: 50%;
  font-size: 1.3rem;
  transform: translateY(-50%);
  opacity: 0.6;
}

#hamburger {
  display: none;
  padding: 0.1rem;
  margin-left: 1rem;
  font-size: 1.9rem;
}

@media (max-width: 1100px) {
  #hamburger {
    display: block;
  }

  .containerz {
    padding: 1.2rem;
  }

  .menu {
    display: none;
    position: fixed;
    top: 87px;
    left: 0;
    min-height: 100vh;
    width: 100vw;
  }

  .menu-bar li:first-child ul:nth-child(1) {
    border-right: none;
    border-bottom: var(--border);
  }

  .dropdown {
    display: none;
    min-width: 100%;
    border: none !important;
    border-radius: 5px;
    position: static;
    top: 0;
    left: 0;
    visibility: visible;
    opacity: 1;
    transform: none;
    box-shadow: none;
  }

  .menu.show,
  .dropdown.active {
    display: block;
  }

  .dropdown ul {
    padding-left: 0.3rem;
  }

  .menu-bar {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 1rem;
  }

  .menu-bar .nav-link {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 0;
  }

  .menu-bar li:first-child .dropdown {
    min-width: 100%;
  }

  .menu-bar > li:not(:last-child) {
    padding-bottom: 0.5rem;
    border-bottom: var(--border);
  }
}

@media (max-width: 600px) {
  .right-containerz {
    display: none;
  }

  .display-600px {
    display: block !important;
  }
}

</style>
